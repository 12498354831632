import { render, staticRenderFns } from "./form_learn.vue?vue&type=template&id=fdb4df64&scoped=true"
import script from "./form_learn.vue?vue&type=script&lang=js"
export * from "./form_learn.vue?vue&type=script&lang=js"
import style0 from "./form_learn.vue?vue&type=style&index=0&id=fdb4df64&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdb4df64",
  null
  
)

export default component.exports