<template>
    <div class="upload">
        <!-- 上传-->
        <form id="uploadForm"   v-show="false">
            <input ref="fileRef" type="file" @change="fileChange">
        </form>
        

        <!-- 引用 -->
        <!-- <upload 
        v-show="false"  
        ref="uploads"
        :show_mb="show_mb"
        :mbs="mbs"
        :show_type="true"
        :types="types"
         ></upload> -->
            <!-- types:JSON.stringify(['jpg','png']) -->
         
    </div>
</template>
<script>
import axios from "axios";
 

export default {
    name: "upload",
    props:{
        // 是否限制大小
        show_mb:{
				type: Boolean,
				default: false
		},
        // 大小
        mbs: {
            type: Number,
            default: 0
		},
        // 是否限制类型
        show_type:{
				type: Boolean,
				default: false
		},
        //类型
        types: {
            type: String,
            default: '[]'
		},
        // 文字提示
        text_up: {
            type: String,
            default: '上传'
		},
    },
    data() {
        return {
            texts:'上传',
            name:'',
            parent_num:0,
            // 实例
            // show_mb:true,//是否限制大小
            // mbs:5,//大小
            // show_type:true,//是否限制类型
            // type:JSON.stringify(['jpg','png','pdf']) ,//类型

        };
    },
    methods: {
        uploadFile(num) {
            this.parent_num=num||0
            this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
        },
        fileChange(event) {
         
            // 阻止发生默认行为
            let formData = new FormData()
            let file = this.$refs.fileRef.files[0]
            console.log('上传',file)
            this.name=file.name
            formData.append('file',file)
            // this.$parent.upload_url(1)
            if(this.show_mb){
                let size=Number(file.size) 
                size=size/(1024*1024)
                if(this.mbs<size){
                    // this.$parent.upload_url(2)
                    alertErr(`当前限制的文件大小为${this.mbs}mb`)
                    document.getElementById("uploadForm").reset()
                    
                    return
                }
            }
            if(this.show_type){
                let type=file.name.split('.')
                // this.types=JSON.parse(this.types)//不能修改父组件传来的值会报错
                let types_in=JSON.parse(this.types)
                console.log('type',type)
                let flag1= types_in.findIndex(item => item===type[1] )
                console.log(flag1)
                if(flag1==-1){
                    // this.$parent.upload_url(2)
                    let types=types_in.join(',')
                    console.log('types',types)
                    alertErr(`当前限制的文件类型${types}格式`)
                    document.getElementById("uploadForm").reset()
                    
                    return
                }
            }
            this.onUpload(formData)
        },
        onUpload (formData) {
            this.$api_up("upload",formData ,"post",{}).then((res) => {
                if(res.code==200){
                    console.log("文件", res);
                    alertSucc('上传成功')
                    let datas={
                        name: this.name
                    }
                    this.$parent.upload_url(res.data,datas,this.parent_num)
                    // 上传成功的是时候去除原来的文件
                    document.getElementById("uploadForm").reset()
                    // this.$refs.fileRef.reset()
                }else{
                    document.getElementById("uploadForm").reset()
                    // alert(res)
                    alertErr(res.msg)
                }
              
            });
        }
    },
    computed: {
    },

    watch: {},


    created() {
    this.texts=this.text_up
    },


};
</script>
  
<style scoped lang="less">
.upload {
   
}
</style>
  