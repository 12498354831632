<template>
    <div class="dialog_box">
        <el-dialog title="现任职务" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed">
            <div class="form_box">
                <div class="form_flex">
                    <div class="form_item">
                        <div class="form_title"><span>*</span>现任职务:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入现任职务" v-model="form.zhiwu">
                        </div>
                    </div>
                  
                     
                  
                </div>
                <div class="form_btns">
                    <div class="btns1" @click="handle_ends">保存</div>
                    <div class="btns2" @click="handle_close">取消</div>
                </div>
                
            </div>
        </el-dialog>

    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    props: {

    },
    data() {
        return {
            shows: false,
            form:{
                user_id:0,
                zhiwu:'',
            },

        };
    },
    methods: {
        onclosed(){
            this.form={
                user_id:0,
                zhiwu:'',
            }
        },
        //打开
        handle_open(id,infos) {
            if(infos){
                let info=JSON.parse(JSON.stringify(infos))||{}
                // this.form.fo
                for (const key in this.form) {
                   this.form[key]=info[key]
                }
            }
            this.form.user_id=id
            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false

        },
        //确认
        handle_ends() {
            if(!this.form.zhiwu){
                alertErr('请输入现任职务')
                return
            }
            this.loading = true
            this.$api("editUserResume", {
                ...this.form
            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false
                    this.$emit("handle_get_detial");

                } else {
                    alertErr(res.msg)
                }
            });
        },
    },
    computed: {
    },

    watch: {
        
    },


    created() {


    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 760px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }

    .form_box {
       
        .form_flex{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-bottom: 1px solid #EEEEEE ;
            padding-bottom: 23px;
            .form_item{
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 17px;
                .form_title{
                    width: 130px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                .form_input1{
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    border: 1px solid #D4D4D4;
                    margin-left: 11px;
                    input{
                        width: 100%;
                        height: 40px;
                        padding: 0 10px;                      
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;

                    }
                }
                .form_input2{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
                .form_input3{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
                .form_input4{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .form_btns{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 18px;
            .btns1{
                width: 91px;
                height: 38px;
                background: #2373C8;
                border-radius: 4px 4px 4px 4px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
            }
            .btns2{
                width: 91px;
                height: 38px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B8B8B8; 
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #6A6A6A;
                margin-left: 22px;
            }
        }
    }
}
</style>