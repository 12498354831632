<template>
    <div class="dialog_box">
        <el-dialog title="家庭主要成员及重要社会关系" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed">
            <div class="form_box">
                <div class="form_flex">
                    <div class="form_item">
                        <div class="form_title"><span>*</span>称谓:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入称谓" v-model="form.title">
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>姓名:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入姓名 " v-model="form.name">
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>出生日期:</div>
                        <div class="form_input3">
                            <el-date-picker v-model="form.birthday" type="date" placeholder="请选择出生日期"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title">政治面貌:</div>
                        <div class="form_input4">
                            <el-select v-model="form.zzmm" placeholder="请选择">
                                <el-option
                                v-for="item in zzmm_list"
                                :key="item.title"
                                :label="item.title"
                                :value="item.title">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    
                    <div class="form_item2">
                        <div class="form_title">工作单位及职务:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入工作单位及职务" v-model="form.job_title">
                        </div>
                    </div>
                </div>
                <div class="form_btns">
                    <div class="btns1" @click="handle_ends">保存</div>
                    <div class="btns2" @click="handle_close">取消</div>
                </div>
                
            </div>
        </el-dialog>

    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    props: {

    },
    data() {
        return {
            shows: false,
            user_id:0,
            form:{
                title:'',//称谓
                name:'',//姓名
                birthday:'',//出生日期
                zzmm:'',//政治面貌
                job_title:'',//工作单位
            },
            zzmm_list:[
                {
                    title:'群众'
                },
                {
                    title:'团员'
                },
                {
                    title:'党员'
                },
            ]

        };
    },
    methods: {
        onclosed(){
            this.form={
                title:'',//称谓
                name:'',//姓名
                birthday:'',//出生日期
                zzmm:'',//政治面貌
                job_title:'',//工作单位
            }
        },

        //打开
        handle_open(user_id,item) {
            this.user_id=user_id
            this.time_show=false
            if(item){
                this.form=JSON.parse(JSON.stringify(item))
            }else{
                this.form={
                    name:'',//姓名
                    birthday:'',//出生日期
                    zzmm:'',//政治面貌
                    title:'',//称谓
                    job_title:'',//工作单位
                }

            }
            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false

        },
        //确认
        handle_ends() {
            if(!this.form.title){
                alertErr('请输入称谓')
                return
            }
            if(!this.form.name){
                alertErr('请输入姓名')
                return
            }
            if(!this.form.birthday){
                alertErr('请选择出生日期')
                return
            }
            if(!this.form.zzmm){
                alertErr('请选择政治面貌')
                return
            }
            // if(!this.form.job_title){
            //     alertErr('请输入工作单位及职务')
            //     return
            // }
            this.loading = true
            this.$api("editUserFamily", {
                user_id:this.user_id,
                ...this.form

            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false
                    this.$emit("handle_get_detial");

                } else {
                    alertErr(res.msg)
                }
            });
        },
    },
    computed: {
    },

    watch: {
        
    },


    created() {


    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 760px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }

    .form_box {
       
        .form_flex{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-bottom: 1px solid #EEEEEE ;
            padding-bottom: 23px;
            .form_item{
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 17px;
                .form_title{
                    width: 126px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                .form_input1{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    border: 1px solid #D4D4D4;
                    margin-left: 11px;
                    input{
                        width: 190px;
                        height: 40px;
                        padding: 0 10px;                      
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;

                    }
                }
                .form_input2{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
                .form_input3{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
                .form_input4{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
            }
            .form_item2{
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 17px;
                .form_title{
                    width: 126px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    flex-shrink: 0;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                .form_input1{
                    width: 550px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    border: 1px solid #D4D4D4;
                    margin-left: 11px;
                    input{
                        width: 544px;
                        height: 40px;
                        padding: 0 10px;                      
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;

                    }
                }
            }
        }
        .form_btns{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 18px;
            .btns1{
                width: 91px;
                height: 38px;
                background: #2373C8;
                border-radius: 4px 4px 4px 4px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
            }
            .btns2{
                width: 91px;
                height: 38px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B8B8B8; 
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #6A6A6A;
                margin-left: 22px;
            }
        }
    }
}
</style>