<template>
    <div class="dialog_box">
        <el-dialog title="工作学习简历" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed">
            <div class="form_box">
                <div class="form_flex">
                    <div class="form_item">
                        <div class="form_title"><span>*</span>工作时间:</div>
                        <div class="form_input1">
                            <div class="time1">
                                <el-date-picker v-model="form.s_time" type="date" placeholder="请选择开始时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                            <div class="orders">-</div>
                            <div class="time2">
                                <el-date-picker v-model="form.e_time" type="date" placeholder="请选择结束时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" :disabled="time_show">
                                </el-date-picker>
                            </div>
                            <div class="time3">
                                <el-checkbox v-model="time_show" @change="changes">至今</el-checkbox>
                            </div>
                        </div>
                    </div>
                  
                    <div class="form_item">
                        <div class="form_title"><span>*</span>工作学习内容:</div>
                        <div class="form_input2">
                            <!-- <input type="text" placeholder="请输入现任职务"> -->
                            <el-input type="textarea" v-model="form.title" aria-placeholder="请输入工作学习内容"></el-input>
                        </div>
                    </div>
                    
                  
                </div>
                <div class="form_btns">
                    <div class="btns1" @click="handle_ends">保存</div>
                    <div class="btns2" @click="handle_close">取消</div>
                </div>
                
            </div>
        </el-dialog>

    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    props: {

    },
    data() {
        return {
            shows: false,
            user_id:0,//固定
            form:{
                title:'',//工作内容
                s_time:'',//开始时间
                e_time:'',//结束时间
            },
            time_show:false,

        };
    },
    methods: {
        onclosed(){
            this.form={
                title:'',//工作内容
                s_time:'',//开始时间
                e_time:'',//结束时间
            }
        },

        //打开
        handle_open(user_id,itme) {
            this.user_id=user_id
            this.time_show=false
            if(itme){
                this.form=JSON.parse(JSON.stringify(itme))
                if(itme.e_time=='至今'){
                    this.time_show=true
                }
            }else{
                this.form={
                    title:'',//工作内容
                    s_time:'',//开始时间
                    e_time:'',//结束时间
                }

            }
            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false

        },
        //确认
        handle_ends() {
          
            if(!this.form.s_time){
                alertErr('请选择开始时间')
                return
            }
            if(!this.form.e_time){
                alertErr('请选择结束时间')
                return
            }
            if(!this.form.title){
                alertErr('请输入工作学习内容')
                return
            }


            this.loading = true
            this.$api("editUserWork", {
                user_id:this.user_id,
                ...this.form

            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false
                    this.$emit("handle_get_detial");

                } else {
                    alertErr(res.msg)
                }
            });
        },
        changes(){
            console.log('time_show',this.time_show)
            if(this.time_show){
                let date = new Date(),
                year = date.getFullYear(), //获取完整的年份(4位)
                month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
                strDate = date.getDate() // 获取当前日(1-31)
            if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
            if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0

                // this.form.time=year+'-'+month+'-'+strDate
                this.form.e_time='至今'


            }else{
                this.form.e_time=''
            }
        },
    },
    computed: {
    },

    watch: {
        
    },


    created() {


    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 760px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }

    .form_box {
       
        .form_flex{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-bottom: 1px solid #EEEEEE ;
            padding-bottom: 23px;
            .form_item{
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 17px;
                .form_title{
                    width: 130px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                .form_input1{
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                    .time1{

                    }
                    .orders{
                        padding: 0 21px;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 12px;
                        color: #333333;

                    }
                    .time2{

                    }
                    .time3{
                        padding-left: 15px;
                    }
                
                }
                .form_input2{
                    width: 100%;
                    // height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
              
            }
        }
        .form_btns{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 18px;
            .btns1{
                width: 91px;
                height: 38px;
                background: #2373C8;
                border-radius: 4px 4px 4px 4px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
            }
            .btns2{
                width: 91px;
                height: 38px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B8B8B8; 
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #6A6A6A;
                margin-left: 22px;
            }
        }
    }
}
</style>