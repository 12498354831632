<template>
    <div class="Employee_Information_resume" v-loading="loading">
        <div class="top_title">添加简历</div>
        <div class="user_info">
            <div class="lefts">
                <div class="imgs">
                    <img src="../../assets/img/employee/user_img.png" alt=""  @click="handle_up" v-if="!infos.image">
                    <img :src="infos.image" alt=""  @click="handle_up" v-if="infos.image">
                    <div class="up_img_text" @click="handle_up">上传照片</div>
                </div>
                <div class="info_box">
                    <div class="names">{{infos.name||'姓名'}}</div>
                    <div class="form_flex">
                        <div class="form_item">性别：{{infos.sex}}</div>
                        <div class="form_item">籍贯：{{infos.native}}</div>
                        <div class="form_item">参加工作时间：{{infos.gz_time}}</div>

                        <div class="form_item">出生年月：{{infos.birthday}}</div>
                        <div class="form_item">出生地：{{infos.address}}</div>
                        <div class="form_item">健康状况：{{infos.jiankang}}</div>

                        <div class="form_item">民族：{{infos.minzu}}</div>
                        <div class="form_item">入党时间：{{infos.rd_time}}</div>

                        <div class="form_item">专业技术职务：{{infos.zyzw}}</div>
                        <div class="form_item">熟悉专业有何专长：{{infos.zyzc}}</div>
                    </div>
                </div>
            </div>
            <div class="rights">
                <div class="btns" @click="handle_user_btn">
                    <img src="../../assets/img/employee/xuigai.png" alt="">
                    编辑
                </div>
            </div>
        </div>
        <div class="add_info">
            <div class="tops_box">
                <div class="title_flex">
                    <img src="../../assets/img/employee/icon1.png" alt="">
                    学历学位
                </div>
                <div class="btns_box">
                    <div class="btns" @click="handle_xlxw_brn">
                        <img src="../../assets/img/employee/xuigai.png" alt="">
                        编辑
                    </div>
                </div>
            </div>
            <div class="bom_box1">
                <div class="info_flex">
                    <div class="info_text1">全日制教育：{{infos.qrjy=='null'?'':infos.qrjy}}</div>
                    <div class="info_text2">在职教育：{{infos.zzjy=='null'?'':infos.zzjy}}</div>
                </div>
                <div class="info_flex">
                    <div class="info_text1">毕业院校系及专业：{{infos.qr_title=='null'?'':infos.qr_title}}</div>
                    <div class="info_text2">毕业院校系及专业：{{infos.zz_title=='null'?'':infos.zz_title}}</div>
                </div>
            </div>
        </div>
        <div class="add_info">
            <div class="tops_box">
                <div class="title_flex">
                    <img src="../../assets/img/employee/icon2.png" alt="">
                    现任职务
                </div>
                <div class="btns_box">
                    <div class="btns" @click="handle_zw_brn">
                        <img src="../../assets/img/employee/xuigai.png" alt="">
                        编辑
                    </div>
                </div>
            </div>
            <div class="bom_box1">
                <div class="info_flex">
                    <div class="info_text1">现任职务：{{infos.zhiwu=='null'?'':infos.zhiwu}}</div>
                    <!-- <div class="info_text2">在职教育：硕士</div> -->
                </div>
            </div>
        </div>
        <div class="add_info">
            <div class="tops_box">
                <div class="title_flex">
                    <img src="../../assets/img/employee/icon3.png" alt="">
                    工作学习简历
                </div>
                <div class="btns_box">
                    <div class="btns" @click="hadndle_learn_add">
                        <img src="../../assets/img/employee/xinzeng.png" alt="">
                        新增
                    </div>
                </div>
            </div>
            <div class="bom_box2">
                <div class="info_flex" v-for="(item,index) in work" :key="index">
                    <div class="info_text_flex">
                        <div class="info_text1">{{item.s_time}}-{{item.e_time}}</div>
                        <div class="info_text2">{{item.title}}</div>
                    </div>
                    <div class="info_btns">
                        <div class="btns1"  @click="hadndle_learn_xg(item)"><img src="../../assets/img/employee/xuigai.png" alt="">编辑</div>
                        <div class="btns2" @click="hadndle_learn_sc(item)">删除</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add_info">
            <div class="tops_box">
                <div class="title_flex">
                    <img src="../../assets/img/employee/icon4.png" alt="">
                    家庭主要成员及重要社会关系
                </div>
                <div class="btns_box">
                    <div class="btns" @click="hadndle_family_add">
                        <img src="../../assets/img/employee/xinzeng.png" alt="">
                        新增
                    </div>
                </div>
            </div>
            <div class="bom_box3">
                <div class="info_flex" v-for="(item,index) in familys" :key="index">
                    <div class="info_text1">{{item.title}}</div>
                    <div class="info_text2">{{item.name}}</div>
                    <div class="info_text3">{{item.birthday}}</div>
                    <div class="info_text4">{{item.zzmm}}</div>
                    <div class="info_text5">{{item.job_title}}</div>
                    <div class="info_btns">
                        <div class="btns1" @click="hadndle_family_xg(item)"><img src="../../assets/img/employee/xuigai.png" alt="">编辑</div>
                        <div class="btns2" @click="hadndle_family_sc(item)">删除</div>
                    </div>
                </div>
            </div>
        </div>

        <form_user ref="form_user" @handle_get_detial="handle_get_detial"></form_user>
        <form_degree ref="form_degree" @handle_get_detial="handle_get_detial"></form_degree>
        <form_zw ref="form_zw" @handle_get_detial="handle_get_detial"></form_zw>
        <form_learn ref="form_learn" @handle_get_detial="handle_get_detial"></form_learn>
        <form_family ref="form_family" @handle_get_detial="handle_get_detial"></form_family>
        <upload_img ref="upload_img"></upload_img>

    </div>
</template>
<script>
import form_user from "@/components/Employee_Information_resume/form_user.vue"; //基础信息
import form_degree from "@/components/Employee_Information_resume/form_degree.vue"; //学历学位
import form_zw from "@/components/Employee_Information_resume/form_zw.vue"; //现任职务
import form_learn from "@/components/Employee_Information_resume/form_learn.vue"; //工作学习简历
import form_family from "@/components/Employee_Information_resume/form_family.vue"; //家庭主要成员及重要社会关系
import upload_img from '@/components/upload/upload.vue'

export default {
    name: "Customer_Information_form",
    components: {
        form_user,
        form_degree,
        form_zw,
        form_learn,
        form_family,
			upload_img,

    },
    data() {
        return {
            user_id:0,
            loading:false,
            infos:{
                name:'',
            },//顶部个人
            work:[],//工作学习简历
            familys:[],//家庭主要成员及重要社会关系

        };
    },
    methods: {
        handle_user_btn(){
            this.$refs.form_user.handle_open(this.user_id,this.infos)
        },
        handle_xlxw_brn(){
            this.$refs.form_degree.handle_open(this.user_id,this.infos)
        },
        handle_zw_brn(){
            this.$refs.form_zw.handle_open(this.user_id,this.infos)
        },
        hadndle_learn_add(){
            this.$refs.form_learn.handle_open(this.user_id)

        },
        hadndle_learn_xg(item){
            this.$refs.form_learn.handle_open(this.user_id,item)
        },
        hadndle_learn_sc(item){
            this.$confirm('是否删除改信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.$api("delUserWork", {
                    id:item.id
                }, "post").then((res) => {
                    console.log('详情',res)
                    this.loading = false
                    if (res.code == 200) {
                        this.get_detial()
                    
                    } else {
                        alertErr(res.msg)
                    }
                });
            }).catch(() => {
                        
            });
        },
        hadndle_family_add(){
            this.$refs.form_family.handle_open(this.user_id)

        },
        hadndle_family_xg(item){
            this.$refs.form_family.handle_open(this.user_id,item)
        },
        hadndle_family_sc(item){
            this.$confirm('是否删除改信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.$api("delUserFamily", {
                    id:item.id
                }, "post").then((res) => {
                    console.log('详情',res)
                    this.loading = false
                    if (res.code == 200) {
                        this.get_detial()
                    
                    } else {
                        alertErr(res.msg)
                    }
                });
            }).catch(() => {
                        
            });
        },
        handle_get_detial(){
            this.get_detial()
        },

        get_detial(){
            this.loading = true
            this.$api("getUserResume", {
                user_id:this.$route.query.id
            }, "get").then((res) => {
                console.log('详情',res)
                this.loading = false
                if (res.code == 200) {
                    this.infos=res.data.info||{}
                    this.work=res.data.work
                    this.familys=res.data.familys
                  
                } else {
                    alertErr(res.msg)
                }
            });
        },
        handle_up(){
			this.$refs.upload_img.uploadFile()
        },
        upload_url(url){
            console.log(url)
          
            this.loading = true
            this.$api("editUserResume", {
                user_id:this.$route.query.id,
                image:url.path
            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false
                    this.handle_get_detial()

                } else {
                    alertErr(res.msg)
                }
            });
        },
    },
    computed: {
    },

    watch: {

    },


    created() {
        this.user_id=this.$route.query.id
        this.get_detial()
    },


};
</script>

<style scoped lang="less">
.Employee_Information_resume {
    width: 1640px;
    min-height: 100%;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #E6E6E6;
    padding: 19px 17px;

    .top_title {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
    }

    .user_info {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        padding: 44px 50px;

        .lefts {
            display: flex;

            // align-items: center;
            .imgs {
                width: 145px;
                flex-shrink: 0;

                img {
                    width: 145px;
                    height: 164px;
                    cursor: pointer;
                }

                .up_img_text {
                    text-align: center;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #6A6A6A;
                    // padding-top: 18px;
                    margin-top: 18px;
                    cursor: pointer;
                }
            }

            .info_box {
                padding-left: 34px;

                .names {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: bold;
                    font-size: 18px;
                    color: #000000;
                    margin-bottom: 20px;

                }

                .form_flex {
                    display: flex;
                    flex-wrap: wrap;

                    .form_item {
                        width: 300px;
                        margin-bottom: 20px;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;

                    }
                }
            }
        }

        .rights {
            width: 50px;
            flex-shrink: 0;

            .btns {
                display: flex;
                // align-items: center;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                cursor: pointer;

                // white-space:nowrap;
                // line-height: 20px;
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                }

                &:hover {
                    color: #3697FD;

                }
            }

        }
    }

    .add_info {
       
        padding: 0 50px;
        margin-bottom: 70px;
        .tops_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 22px;
            .title_flex{
                display: flex;
                align-items: center;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 16px;
                color: #333333;
                img{
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }

            }
            .btns_box{
                width: 50px;
                flex-shrink: 0;
                .btns {
                    display: flex;
                    align-items: center;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    cursor: pointer;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                    }
                    &:hover {
                        color: #3697FD;

                    }
                }
            }
        }

        .bom_box1 {
           .info_flex{
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .info_text1{
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    width: 500px;
                }
                .info_text2{
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }

           }

        }
        .bom_box2{
            .info_flex{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                .info_text_flex{
                    display: flex;
                    .info_text1{
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                        width: 300px;
                    }
                    .info_text2{
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                    }
                }
                .info_btns{
                    display: flex;
                    align-items: center;
                    .btns1{
                        display: flex;
                        align-items: center;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                        cursor: pointer;
                        img{
                            width: 15px;
                            height: 15px;
                            margin-right: 5px;
                        }
                        &:hover{
                            color: #2373C8;
                        }
                    }
                    .btns2{
                        cursor: pointer;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #2373C8;
                        margin-left: 20px;
                    }
                }
              

           }
        }
        .bom_box3{
            .info_flex{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                .info_text1{
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    width: 100px;
                    flex-shrink: 0;

                }
                .info_text2{
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    width: 100px;
                    flex-shrink: 0;

                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }
                .info_text3{
                    width: 100px;
                    flex-shrink: 0;

                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }
                .info_text4{
                    width: 100px;
                    flex-shrink: 0;

                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }
                .info_text5{
                    width: 400px;
                    flex-shrink: 0;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }
                .info_btns{
                    display: flex;
                    align-items: center;
                    .btns1{
                        display: flex;
                        align-items: center;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                        cursor: pointer;
                        img{
                            width: 15px;
                            height: 15px;
                            margin-right: 5px;
                        }
                        &:hover{
                            color: #2373C8;
                        }
                    }
                    .btns2{
                        cursor: pointer;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #2373C8;
                        margin-left: 20px;
                    }
                }

           }
        }
    }


}
</style>